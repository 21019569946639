<template>
   <!-- start::outer div -->
   <div>
	  <!-- start::overlay container -->
	  <div :class="{ show: drawerActive }"
		   class="body-content-overlay" />
	  <!-- end::overlay container -->

	  <!-- start::table container card -->
	  <b-card class="mb-0"
			  no-body>
		 <div class="m-2">
			<b-row>
			   <b-col cols="6">
				  <div class="d-flex align-items-center">
					 <!-- start::column per page -->
					 <template v-if="perPageOptions.length >= 1">
						<b-row class="mr-0">
						   <b-col
							   class="
                      d-flex
                      align-items-center
                      justify-content-start
                      mb-1 mb-md-0
                    "
							   cols="12"
						   >
							  <v-select
								  v-model="filters.selectedPageOptions"
								  :clearable="false"
								  :options="perPageOptions"
								  class="per-page-selector d-inline-block mx-50"
								  @input="pageOptionChanged"
							  >
								 <template v-slot:no-options>{{
									   $t("errors.no-matching")
															 }}
								 </template>
							  </v-select>
						   </b-col>
						</b-row>
					 </template>
					 <!-- end::column per page -->
					 <!--					 <table-filters @selectedCategory="(e)=>this.filters.categoryId = e.id"-->
					 <table-filters
						 :categories="getAllCategories"
						 :hasStatus="false"
						 @selectedBalance="onBalance"
						 @selectedCategory="onFilter"
					 ></table-filters>

					 <default-filter :order="order" :sort="sort" defaultFilter="Όνομα" @default="defaultFilter"/>
				  </div>
			   </b-col>

			   <!-- start::column search -->
			   <b-col cols="12"
					  md="6">
				  <div class="d-flex align-items-center justify-content-end">
					 <b-form-input
						 v-model="filters.term"
						 aria-placeholder="Αναζήτηση..."
						 class="d-inline-block mr-1"
					 />
					 <b-button
						 v-b-toggle.sidebar-task-handler
						 class="text-nowrap"
						 variant="primary"
					 >
						<feather-icon class="mr-25"
									  icon="PlusIcon" />
						<span>{{ $t("generic.add") }}</span>
					 </b-button>
				  </div>
			   </b-col>
			   <!-- end::column search -->
			</b-row>
		 </div>

		 <!-- start::table -->
		 <b-table
			 id="contacts-table"
			 :busy="isLoading"
			 :current-page="filters.currentPage"
			 :empty-text="$t('errors.no-entries-found')"
			 :fields="getContactTableColumns"
			 :items="getContacts.data"
			 :perPage="0"
			 class="position-relative white-space"
			 no-local-sorting
			 primary-key="id"
			 responsive
			 show-empty
			 :sort-by="getOrderName(this.order)"
			 :sort-desc="this.sort==='desc'"
			 @sort-changed="sortingChanged"
		 >
			<template #empty="scope">
			   <div class="text-center py-10 my-3">
				  <feather-icon
					  class="text-primary op2"
					  icon="UserPlusIcon"
					  size="70"
				  />
				  <h4 class="my-1 op2">{{ $t("generic.notfound-generic") }}</h4>
			   </div>
			</template>

			<template #table-busy>
			   <div class="text-center text-danger my-2">
				  <!--				  <b-spinner class="align-middle"></b-spinner>-->
				  <strong>{{ $t("generic.loading") }}</strong>
			   </div>
			</template>

			<!-- start::first column -->
			<template #cell(name)="data">
			   <b-media verical-align="center">
				  <template #aside>
					 <b-avatar
						 v-if="data.item.photo"
						 :src="data.item.photo"
						 :text="data.item.name"
						 size="32"
						 variant="light"
					 />
					 <b-avatar
						 v-else
						 :text="fullName(data.item.surname, data.item.name)"
						 :variant="getColor(data.item.color)"
						 size="32"
					 />
				  </template>
				  <b-link
					  :to="{ name: 'contacts-view', params: { userId: data.item.id } }"
					  class="font-weight-bold d-block text-nowrap"
				  >
					 {{ truncate(data.item.surname, data.item.name) }}
				  </b-link>
				  <small v-if="data.item.company !== null"
						 class="text-muted">{{
						showCompany(data.item)
											}}</small>
				  <!--				  <small v-else-if="data.item.company.name !==null">#{{ data.item.id }}</small>-->
				  <small v-else
						 class="text-muted">#{{ data.item.id }}</small>
			   </b-media>
			</template>
			<!-- end:: first column -->

			<!-- start::financial column -->
			<!-- Αν έχει υπόλοιπο μπαίνει το variant="light-danger" και icon PieChartIcon-->
			<template #cell(financial)="data">
			   <b-avatar
				   :id="`invoice-row-${data.item.id}`"
				   size="32"
				   variant="light-success"
			   >
				  <feather-icon icon="CheckCircleIcon" />
			   </b-avatar>
			   <b-tooltip :target="`invoice-row-${data.item.id}`"
						  placement="top">
				  <p class="mb-0">
					 {{ data.item.all_years_ }}
					 Πληρωμές: €{{ convertFormatPrice(data.item.all_years_payments) }}
				  </p>
				  <p class="mb-0">
					 Υπόλοιπο: €{{
						getSum(data.item.all_years_tziros, data.item.all_years_payments)
					 }}
				  </p>
			   </b-tooltip>
			</template>
			<!-- end::financial column -->

			<!-- start::category column -->
			<template #cell(category)="data">
			   {{ data.item.category }}
			</template>
			<!-- end::category column -->

			<!-- start::column vatNumber -->
			<template #cell(vatNumber)="data">
			   <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ data.item.vatNumber }}
            </span>
			   </div>
			</template>
			<!-- end::column vatNumber -->

			<!-- start::column mobile -->
			<template #cell(mobile)="data">
			   {{ data.item.mobile }}
			</template>
			<!-- end::column mobile -->

			<!-- start::column phone -->
			<template #cell(phone)="data">
			   {{ data.item.phone }}
			</template>
			<!-- end::column phone -->

			<!-- start::column actions -->
			<template #cell(actions)="data">
			   <div class="text-nowrap">
				  <feather-icon
					  :id="`invoice-row-${data.item.id}-preview-icon`"
					  class="cursor-pointer"
					  icon="EyeIcon"
					  size="16"
					  @click="
                $router.push({
                  name: 'contacts-view',
                  params: { userId: data.item.id },
                })
              "
				  />
				  <b-tooltip
					  :target="`invoice-row-${data.item.id}-preview-icon`"
					  :title="$t('generic.view')"
				  />

				  <b-dropdown no-caret
							  right
							  variant="link">
					 <template #button-content>
						<feather-icon
							class="align-middle text-body"
							icon="MoreVerticalIcon"
							size="16"
						/>
					 </template>

					 <b-dropdown-item
						 :to="{
                  name: 'contacts-edit',
                  params: { userId: data.item.id },
                }"
					 >
						<feather-icon icon="EditIcon" />
						<span class="align-middle ml-50">{{ $t("generic.edit") }}</span>
					 </b-dropdown-item>

					 <b-dropdown-item @click="deleteContact(data)">
						<feather-icon icon="TrashIcon" />
						<span class="align-middle ml-50">{{
							  $t("generic.delete")
														 }}</span>
					 </b-dropdown-item>
				  </b-dropdown>
			   </div>
			</template>
			<!-- end::column actions -->
		 </b-table>
		 <!-- end::table -->

		 <div class="mx-2 mb-2">
			<b-row>
			   <b-col
				   class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
				   cols="12"
				   sm="6"
			   >
            <span class="text-muted">
              {{ $t("tables.view-page") }} {{ filters.currentPage }}
              {{ $t("tables.from") }}
              {{ Math.round(totalPages / filters.selectedPageOptions) }}
              {{ $t("tables.in-total") }} {{ totalPages }}
              {{ $t("tables.entries") }}.
            </span>
			   </b-col>

			   <b-col
				   class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
				   cols="12"
				   sm="6"
			   >


				  <b-pagination
					  v-model="filters.currentPage"
					  :per-page="filters.selectedPageOptions"
					  :total-rows="totalPages"
					  aria-controls="contacts-table"
					  class="mb-0 mt-1 mt-sm-0"
					  first-number
					  last-number
					  next-class="next-item"
					  prev-class="prev-item"
				  >
					 <template #prev-text>
						<feather-icon icon="ChevronLeftIcon"
									  size="18" />
					 </template>
					 <template #next-text>
						<feather-icon icon="ChevronRightIcon"
									  size="18" />
					 </template>
				  </b-pagination>
			   </b-col>
			</b-row>
		 </div>
	  </b-card>
	  <!-- end::table container card -->

	  <!-- start::addNew sidebar -->
	  <add-contact></add-contact>
	  <!-- end::addNew sidebar -->
   </div>
   <!-- end::outer div -->
</template>

<script>
import {
   BAvatar,
   BButton,
   BCard,
   BCol,
   BDropdown,
   BDropdownItem,
   BFormInput,
   BLink,
   BMedia,
   BPagination,
   BRow,
   BTable,
   BTooltip,
   VBToggle,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {mapGetters} from "vuex";
import TableFilters from "@/views/projects/projects-list/TableFilters.vue";
import AddContact from "@/views/caldo-global/AddContact";
import ToastMixin from "@/Mixins/ToastMixin";
import _ from "lodash";
import CurrencyMixin from "@/Mixins/CurrencyMixin";
import {avatarText} from "@core/utils/filter";
import caldoSettings from "@/caldoSettings";
import DefaultFilter from "@/views/projects/projects-list/DefaultFilter";

export default {
   directives: {
	  "b-toggle": VBToggle,
   },
   components: {
	  DefaultFilter,
	  BCard,
	  BRow,
	  BCol,
	  vSelect,
	  BFormInput,
	  BButton,
	  BTable,
	  BTooltip,
	  BMedia,
	  BAvatar,
	  BLink,
	  BDropdown,
	  BDropdownItem,
	  BPagination,
	  TableFilters,
	  AddContact,
   },
   mixins: [ToastMixin, CurrencyMixin],
   data() {
	  return {
		 drawerActive: false,
		 filters: {
			currentPage: 1,
			selectedPageOptions: this.$store.getters.getDynamicPreference['contactListResultsPerPage'],
			categoryId: null,
			remaining: null,
		 },
		 perPageOptions: [5, 10, 25, 50, 100],
		 recordsPerPage: 10,
		 totalPages: 0,
		 isLoading: true,
		 order: "",
		 sort: "",
	  };
   },
   watch: {
	  filters: {
		 deep: true,
		 handler() {

			this.fetchPages(this);
		 },
	  },
   },
   computed: {
	  ...mapGetters(["getContactTableColumns", "getContacts", 'getDynamicPreference']),

	  getAllCategories() {
		 let category = _.uniqBy(this.$store.getters.getCategories, "id");
		 if (category[0]?.id === undefined) {
			category = [];
		 }
	  },

	  resolveUserRoleIcon() {
		 return (gender) => {
			if (gender === 1) return "SettingsIcon";
			if (gender === 2) return "DatabaseIcon";
			if (gender === 3) return "Edit2Icon";
			return "UserIcon";
		 };
	  },
   },
   methods: {
	  fullName(name, surname) {
		 return avatarText(name, surname);
	  },
	  getColor(color) {
		 return caldoSettings.getColor(color);
	  },
	  truncate(name, surname) {
		 return caldoSettings.truncateString(`${name} ${surname}`);
	  },
	  onBalance(e) {
		 this.filters.remaining = e ?? null;
	  },
	  async pageOptionChanged(value) {
		 this.isLoading = true;
		 this.selectedPageOptions = value
		 await this.$store.dispatch("updatePageSelectedOption", value);
		 await this.$store.dispatch("setDynamicPreferences", ['contactListResultsPerPage', value]);
		 setTimeout(() => {

			// this.isLoading = false;
		 }, 200)
	  },
	  onFilter(e) {
		 this.filters.categoryId = e?.id ?? null;
	  },
	  getSum(tziros, paid) {
		 const result = tziros - paid;
		 return this.convertFormatPrice(result);
	  },

	  async sortingChanged(value) {
		 // this.isLoading = true;
		 let order = {
			"category.title": "category",
			"company.vat": "vat",
			name: "surname",
			id: "id",
		 };
		 this.order = order[value.sortBy];
		 this.sort = value.sortDesc ? "desc" : "asc";
		 await this.$store.dispatch("fetchContactCategories", {
			params: {
			   page: value.currentPage,
			   rpp: this.selectedPageOptions,
			   categoryId: this.filters.categoryId,
			   status: this.filters.remaining,
			   searchKey: this.filters.term === "" ? null : this.filters.term,
			   orderBy: [this.order],
			   sortBy: this.sort,
			},
		 });

		 // self.totalPages = self.$store.getters.getContacts.total;

		 let sortBy;
		 let orderBy;

		 console.log(value.sortBy)
		 if (value.sortBy === 'name') {
			sortBy = 'fullname'
		 } else if (value.sortBy === 'category.title') {
			sortBy = 'category'
		 }
		 else if (value.sortBy === 'id') {
			sortBy = 'id'
		 }

		 orderBy = value.sortDesc ? 'desc' : 'asc'
		 // this.selectedPageOptions = value
		 // await this.$store.dispatch("updatePageSelectedOption", value);
		 await this.$store.dispatch("setDynamicPreferences", ['clients_list_order', `${sortBy}_${orderBy}`]);


	  },
	  async defaultFilter(){

		 await this.$store.dispatch("setDynamicPreferences", ['clients_list_order', `fullname_asc`]);
		 this.order = 'name';
		 this.sort = "asc";
		 await this.$store.dispatch("fetchContactCategories", {
			params: {
			   page:1,
			   rpp: this.selectedPageOptions,
			   categoryId: this.filters.categoryId,
			   status: this.filters.remaining,
			   searchKey: this.filters.term === "" ? null : this.filters.term,
			   orderBy: [this.order],
			   sortBy: this.sort,
			},
		 });



	  },
	  showCompany(user) {
		 if (user.company.name) {
			return user.company.name;
		 } else {
			return `#${user.id}`;
		 }
	  },
	  getRandomColor() {
		 var letters = "0123456789ABCDEF";
		 var color = "#";
		 for (var i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		 }
		 return color;
	  },

	  fetchPages: _.debounce(async (self) => {
		 await self.$store.dispatch(
			 "fetchContactCategories",
			 self.filtersHandler()
		 );
		 self.isLoading = false;

		 self.totalPages = self.$store.getters.getContacts.total;
	  }, 100),

	  async deleteContact(payload) {
		 const {isConfirmed} = await this.swal(
			 "Με την διαγραφή του πελάτη, θα διαγραφούν μόνιμα και όλα τα Έργα του."
		 );
		 if (isConfirmed) {
			await this.$store.dispatch("deleteContactCategory", payload);
			this.notify("Διαγράφηκε", `danger`);
		 }
	  },
	  selectedCategory(value) {
	  },
	  filtersHandler() {
		 return {
			params: {
			   page: this.filters.currentPage,
			   rpp: this.filters.selectedPageOptions,
			   categoryId: this.filters.categoryId,
			   status: this.filters.remaining,
			   searchKey: this.filters.term === "" ? null : this.filters.term,
			   orderBy: [this.order],
			   sortBy: this.sort,
			},
		 };
	  },
	  async getOrder() {
		 await this.$store.dispatch('fetchDynamicPreferences')
		 const order = await this.$store.getters.getDynamicPreference['clients_list_order'].split("_")

		 this.order = order[0] === 'fullname' ? 'surname' : order[0];
		 this.sort = order[1];
	  },
	  getOrderName(value) {

		 if (value === 'category') {
			return 'category.title'
		 }
		 if (value === 'name') {
			return 'name';
		 }
		 if (value === 'id') {
			return 'id';
		 }
	  }
   },
   async created() {
	  await this.getOrder()
	  this.filters.selectedPageOptions = await this.$store.getters.getDynamicPreference['contactListResultsPerPage']
   },
   async mounted() {

	  await this.getOrder()
	  await this.fetchPages(this);
	  await this.$store.dispatch("fetchAllCategories", this.filtersHandler());
	  this.isLoading = false;

   },
};
</script>

<style lang="scss"
	   scoped>
@import "@core/scss/vue/libs/vue-select.scss";


.per-page-selector {
   width: 90px;
}


.white-space {
   white-space: nowrap
}


.media-aside {
   top: 4px;
   position: relative;
}


.py-10 {
   padding: 50px 10px;
}


.op2 {
   opacity: 0.6;
}
</style>