<template>
   <!-- NOTE: είναι global για όλους τους πίνακες αλλά δεν ξέρω που να το βάλω -->
   <b-dropdown
	   id="table-filters"
	   ref="dropdown"
	   v-ripple.400="'rgba(255, 255, 255, 0.15)'"
	   left
	   text="Φίλτρα"
	   variant="outline-secondary"
   >
	  <b-dropdown-form class="p-0">
		 <b-row>
			<b-col cols="12">
			   <b-form-group
				   label="Κατηγορία"
				   label-for="project-category"
			   >
				  <v-select
					  v-model="selectedProjectCategories"
					  :options="categories"
					  input-id="project-category"
					  label="title"
					  placehoder="Επιλέξτε..."
					  @input="$emit('selectedCategory',selectedProjectCategories)"
				  >
					 <template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
				  </v-select>
			   </b-form-group>
			</b-col>
			<b-col cols="12">
			   <b-form-group
				   label="Έργα"
				   label-for="project"
			   >
				  <v-select
					  v-model="selectedProject"
					  :options="project"
					  input-id="project"
					  label="title"
					  placehoder="Επιλέξτε..."
					  @input="$emit('selectedProject',selectedProject)"
				  >
					 <template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
				  </v-select>
			   </b-form-group>
			</b-col>
			<b-col v-if="!expenses"
				   cols="12">
			   <b-form-group label="Επαναλαμβανόμενη">
				  <b-form-radio-group
					  v-model="repeatable"
					  :options="RepeatOptions"
					  name="repeatable"
					  @input="$emit('selectedRepeat',repeatable)"
				  />
			   </b-form-group>
			</b-col>
			<b-col v-if="expenses"
				   cols="12">
			   <b-form-group label="Υπόλοιπο">
				  <b-form-radio-group
					  v-model="balance"
					  :options="BalanceOptions"
					  name="balance"
					  @input="$emit('selectedBalance',balance)"
				  />
			   </b-form-group>
			</b-col>
			<b-col v-if="expenses && hasStatus"
				   cols="12">
			   <b-form-group :label="$t('generic.status')">
				  <b-form-radio-group
					  v-model="status"
					  :options="statusOptions"
					  name="status"
					  @input="$emit('selectedStatus',status)"
				  />
			   </b-form-group>
			</b-col>
		 </b-row>
	  </b-dropdown-form>

   </b-dropdown>


</template>

<script>
import {
   BButton,
   BCardText,
   BCol,
   BDropdown,
   BDropdownDivider,
   BDropdownForm,
   BDropdownGroup,
   BDropdownItem,
   BFormCheckbox,
   BFormGroup,
   BFormInput,
   BFormRadio,
   BFormRadioGroup,
   BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import caldoSettings from "@/caldoSettings";

export default {
   components: {
	  BRow,
	  BCol,
	  BButton,
	  BDropdown,
	  BDropdownDivider,
	  BDropdownForm,
	  BDropdownGroup,
	  BDropdownItem,
	  BFormCheckbox,
	  BFormRadio,
	  BFormRadioGroup,
	  BFormGroup,
	  BFormInput,
	  BCardText,
	  vSelect,
   },
   directives: {
	  Ripple,
   },
   props: {
	  categories: Object | Array,
	  project: Object | Array,
	  expenses: {
		 type: Boolean,
		 default: true,
	  },
	  hasStatus: {
		 type: Boolean,
		 default: true,
	  }
   },
   data() {
	  return {
		 selectedProjectCategories: null,
		 selectedProject: null,
		 balance: null,
		 status: '',
		 repeatable: '',
		 BalanceOptions: [
			{text: 'Όλα'},
			{text: 'Εξοφλημένα', value: 'paid'},
			{text: 'Με οφειλή', value: 'pending'},
		 ],
		 RepeatOptions: [
			{text: 'Όλα', value: 'all'},
			{text: 'Ναι', value: 'yes'},
			{text: 'Oxi', value: 'no'},
		 ],
		 statusOptions: caldoSettings.statusOptions
	  }
   },
   methods: {
	  uncheck: function (val) {
		 if (val === this.balance) {
			this.selected = false
		 }
	  }
   }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';


#table-filters .dropdown-menu {
   width: 300px;
}


.custom-control-inline {
   margin-bottom: 0.5rem;
}
</style>