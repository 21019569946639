<template>
   <!-- NOTE: είναι global για όλους τους πίνακες αλλά δεν ξέρω που να το βάλω -->
   <b-dropdown
	   no-caret
	   @click="show"
	   toggle-class="p-0"




	   variant="link"
   >
	  <template #button-content>
		 <div style="padding:10px" 	   class="ml-2 rounded   btn-outline-secondary">

		 <svg style="width: 16px;color: gray;"
			  xmlns="http://www.w3.org/2000/svg"
			  fill="none"
			  viewBox="0 0 24 24"
			  stroke-width="1.5"
			  stroke="currentColor">
			<path stroke-linecap="round"
				  stroke-linejoin="round"
				  d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z" />
		 </svg>

		 </div>
	  </template>

	  <b-dropdown-form style="width: 300px">
		 <b-row>
			<b-col cols="12">
			   <p class="m-0 p-0">Τρέχουσα προβολή</p>
			   <p class="mt-0 mb-1 p-0 font-weight-bold text-primary">{{ getOrder }}</p>
			</b-col>
			<b-col cols="12">
			   <b-button
				   @click="$emit('default')"
				   class="w-100"
				   v-ripple.400="'rgba(113, 102, 240, 0.15)'"
				   size="xl"
				   variant="primary"
			   >
				  Επαναφορά
			   </b-button>
			</b-col>
			<b-col
				class="mt-1"
				cols="12">
			   <p class="m-0 p-0 text-center">Η προκαθορισμένη προβολή των αποτελεσμάτων ειναι</p>
			   <p class="text-center">
				  <b> Ανα {{ defaultFilter }} Δημιουργίας</b>
			   </p>
			</b-col>
		 </b-row>
	  </b-dropdown-form>

   </b-dropdown>


</template>

<script>
import {
   BButton,
   BCardText,
   BCol,
   BDropdown,
   BDropdownDivider,
   BDropdownForm,
   BDropdownGroup,
   BDropdownItem,
   BFormCheckbox,
   BFormGroup,
   BFormInput,
   BFormRadio,
   BFormRadioGroup,
   BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
   components: {
	  BRow,
	  BCol,
	  BButton,
	  BDropdown,
	  BDropdownDivider,
	  BDropdownForm,
	  BDropdownGroup,
	  BDropdownItem,
	  BFormCheckbox,
	  BFormRadio,
	  BFormRadioGroup,
	  BFormGroup,
	  BFormInput,
	  BCardText,
	  vSelect,
   },
   directives: {
	  Ripple,
   },
   props: {
	  defaultFilter: String,
	  order: String,
	  sort: String

   },

   computed: {
	  getOrder() {
		 const order = {
			'title': 'Ανά Τίτλος',
			'category': 'Ανά Κατηγορία',
			'tasks': 'Ανά Task',
			'status': 'Ανά Κατάσταση',
			'deadline': 'Ανά Deadline',
			'remaining': 'Ανά Παραμένων',
			'id': 'Ανά Ημερομηνία',
			'name': 'Ανά Όνομα',
			'datestamp': 'Ανά Ημερομηνία δημιουργίας',
			'amount': 'Ανά Ποσό'
		 }
		 return order[this.order]
	  },
	  getSort() {
		 return this.sort === 'asc' ? 'Αλφαβητικά' : 'Αναλφάβητα'
	  }
   },

   methods: {
	  show(e) {

	  }
   }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';


#table-filters .dropdown-menu {
   width: 300px;
}


.custom-control-inline {
   margin-bottom: 0.5rem;
}


</style>